import React from "react"
import { graphql, Link } from "gatsby"
import styled, { css } from "styled-components"
import Layout from "../components/Layout"
import { media } from "../styles/media"
import Image from "gatsby-image"
import { AiOutlineQuestion } from "react-icons/ai"
import { RiQuestionAnswerLine } from "react-icons/ri"
import { IconContext } from "react-icons"

const IconQ = () => (
  <IconContext.Provider value={{ color: "red", size: "1.62em" }}>
    <AiOutlineQuestion />
  </IconContext.Provider>
)
const IconA = () => (
  <IconContext.Provider value={{ size: "40px", color: "#dadada" }}>
    <RiQuestionAnswerLine />
  </IconContext.Provider>
)

const HeadMedia = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  .details {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 0.62rem;
    color: gray;
    font-size: 0.82rem;
  }
  .backImage {
    width: 85%;
    margin-left: 15%;
  }
  .headCont {
    width: 40%;
    background: linear-gradient(
      245deg,
      rgba(0, 191, 94, 1) 0%,
      rgba(0, 135, 66, 1) 40%,
      rgba(0, 135, 66, 1) 100%
    );
    color: white;
    padding: 2.5rem;
    position: absolute;
    bottom: 0px;
    left: 0;
    h3 {
      font-size: 1.62rem;
    }
    p {
      line-height: 1.62em;
    }
    .comName {
      padding: 1.62rem 0;
      width: 100%;
      &:after {
        display: block;
        width: 23%;
        height: 1px;
        background: white;
        content: "";
        margin-top: 1.62em;
      }
    }
    .personName {
      font-size: 0.9em;
    }
  }
  ${() =>
    media.tablet(css`
      .headCont {
        width: 50%;
        padding: 1.5rem;
        bottom: -3vh;
      }
    `)}
  ${() =>
    media.sp(css`
      .backImage {
        width: 100%;
        margin-left: 0;
      }
      .headCont {
        position: relative;
        width: 90%;
        top: -5vh;
        h3 {
          font-size: 1.32em;
          font-weight: bold;
        }
        p.comName {
          padding: 0.62em 0;
        }
      }
      .details {
        position: relative;
      }
    `)}
`
const Intro = styled.div`
  padding: 80px 15% 50px;
  h4 {
    color: ${({ theme }) => theme.colors.primary.green};
    text-shadow: 0px 0px 2px green;
    margin-bottom: 1.62em;
  }
  p {
    line-height: 1.62em;
    font-size: 1rem;
  }
  ${() =>
    media.tablet(css`
      padding: 100px 10% 50px;
    `)}
  ${() =>
    media.sp(css`
      padding: 20px 5%;
      p {
        font-size: 0.95em;
      }
    `)}
`

const Medias = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 70%;
  margin: 0 auto;
  .gatsby-image-wrapper {
    width: 33%;
  }
  ${() =>
    media.tablet(css`
      width: 80%;
    `)}
  ${() =>
    media.sp(css`
      width: 90%;
    `)}
`

const Qasection = styled.div`
  width: 70%;
  margin: 30px auto;
  .qaSection {
    padding: 50px 0;
  }
  .qSec {
    color: ${({ theme }) => theme.colors.primary.red};
    font-size: 0.8em;
    opacity: 0.8;
  }
  .titleSec {
    font-size: 1.32em;
    margin: 10px 0 20px;
    color: #5a5a5a;
  }
  .answerDetails {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
    p {
      width: 100%;
      line-height: 1.62em;
      color: gray;
      font-size: 0.95em;
      width: 80%;
    }
  }
  ${() =>
    media.tablet(css`
      width: 80%;
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      .qaSection {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0;
      }
    `)}
`

const UnderLinks = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0px 0 50px;
  a {
    background: gray;
    color: white;
    padding: 0.62em 1.62em;
    box-shadow: 10px 10px 0px #dadada;
    transition: 0.6s;
    &:hover {
      box-shadow: 3px 3px 0 #dadada;
    }
  }
`

const VoiceOfEmployees = ({ data }) => {
  // const { slug, name } = data.voice
  return (
    <Layout>
      <HeadMedia>
        <div className="backImage">
          <Image
            fluid={data.voice.headMedia.childImageSharp.fluid}
            alt={data.voice.slug}
          />
        </div>
        <div className="headCont">
          <h3 dangerouslySetInnerHTML={{ __html: data.voice.catchCopy }} />
          <p
            className="comName"
            dangerouslySetInnerHTML={{ __html: data.voice.companyName }}
          />
          <p className="personName">
            {data.voice.depName}
            <br />
            {data.voice.entryYear}年入社：{data.voice.initialName}
            <br />
            {data.voice.gakubuName}卒業
          </p>
        </div>
        <p className="details">※社員の所属・役職は取材当時のものとなります。</p>
      </HeadMedia>
      <Intro>
        <h4>PEOPLE</h4>
        <p
          dangerouslySetInnerHTML={{ __html: data.voice.introductionContent }}
        />
      </Intro>
      <Medias>
        {data.voice.mediaComponents.map((panda, index) => (
          <Image fluid={panda.squareMedia.childImageSharp.fluid} key={index} />
        ))}
      </Medias>
      <Qasection>
        {data.voice.qandaContent.map((panda, index) => (
          <div className="qaSection" key={index}>
            <p className="qSec">
              <IconQ />
              {panda.qContent}
            </p>
            <p className="titleSec">{panda.qSummery}</p>
            <div className="answerDetails">
              <IconA />
              <p dangerouslySetInnerHTML={{ __html: panda.aContent }} />
            </div>
          </div>
        ))}
      </Qasection>
      <UnderLinks>
        <Link to="/voice-of-employees/">先輩社員の声一覧</Link>
      </UnderLinks>
    </Layout>
  )
}

export const query = graphql`
  query VoiceOfEmployeesQuery($slug: String) {
    voice: strapiVoiceOfTheEmployees(slug: { eq: $slug }) {
      slug
      catchCopy
      companyName
      depName
      entryYear
      gakubuName
      initialName
      introductionContent
      headMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mediaComponents {
        id
        squareMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      faceMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      qandaContent {
        id
        qContent
        aContent
        qSummery
      }
    }
  }
`

export default VoiceOfEmployees
